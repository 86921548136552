module.exports ={
  "propertyService": "https://uf26430w2h.execute-api.us-east-1.amazonaws.com/shraddha",
  "domusoApiService": "https://j8g96e2n4h.execute-api.us-east-1.amazonaws.com/shraddha",
  "appService": "https://domusograilsweb-shraddha.devmuso.com",
  "reservationService": "https://k8477f7i4g.execute-api.us-east-1.amazonaws.com/shraddha",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://iuw34l5zy1.execute-api.us-east-1.amazonaws.com/shraddha",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-shraddha.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-shraddha.devmuso.com",
  "maintenanceUrl": "https://iuw34l5zy1.execute-api.us-east-1.amazonaws.com/shraddha/maintenance",
  "env": "shraddha"
}